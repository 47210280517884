const navRoutes = {
    'high-wycombe-property-maintenance': 'high-wycombe-property-maintenance',
    'amersham-property-maintenance': 'amersham-property-maintenance',
    'beaconsfield-property-maintenance': 'beaconsfield-property-maintenance',
    'marlow-property-maintenance': 'marlow-property-maintenance',
    'chesham-property-maintenance': 'chesham-property-maintenance',
    'gerrards-cross-property-maintenance': 'gerrards-cross-property-maintenance',
    'princes-risborough-property-maintenance': 'princes-risborough-property-maintenance',
    'great-missenden-property-maintenance': 'great-missenden-property-maintenance',
    'bourne-end-property-maintenance': 'bourne-end-property-maintenance',
    'chalfont-st-giles-property-maintenance': 'chalfont-st-giles-property-maintenance',
    'chalfont-st-peter-property-maintenance': 'chalfont-st-peter-property-maintenance',
    'stokenchurch-property-maintenance': 'stokenchurch-property-maintenance',
    'wooburn-green-property-maintenance': 'wooburn-green-property-maintenance',
    'flackwell-heath-property-maintenance': 'flackwell-heath-property-maintenance',
    'hughenden-valley-property-maintenance': 'hughenden-valley-property-maintenance',
    'penn-property-maintenance': 'penn-property-maintenance',
    'loudwater-property-maintenance': 'loudwater-property-maintenance',
    'tetsworth-property-maintenance': 'tetsworth-property-maintenance',
    'henley-on-thames-property-maintenance': 'henley-on-thames-property-maintenance',
    'waddesdon-property-maintenance': 'waddesdon-property-maintenance',
    'thame-property-maintenance': 'thame-property-maintenance',
    'chinnor-property-maintenance': 'chinnor-property-maintenance',
    'bicester-property-maintenance': 'bicester-property-maintenance',
    'aylesbury-property-maintenance': 'aylesbury-property-maintenance',
    'hazlemere-property-maintenance': 'hazlemere-property-maintenance',
    'the-laceys-property-maintenance': 'the-laceys-property-maintenance',
    'wooburn-property-maintenance': 'wooburn-property-maintenance',
    'bradenham-property-maintenance': 'bradenham-property-maintenance',
    'saunderton-property-maintenance': 'saunderton-property-maintenance',
    'walters-ash-property-maintenance': 'walters-ash-property-maintenance',
    'radnage-property-maintenance': 'radnage-property-maintenance',
    'lane-end-property-maintenance': 'lane-end-property-maintenance',
    'totteridge-property-maintenance': 'totteridge-property-maintenance',
    'daws-hill-property-maintenance': 'daws-hill-property-maintenance',
    'claytons-property-maintenance': 'claytons-property-maintenance',
    'downley-property-maintenance': 'downley-property-maintenance',
    'cryers-hill-property-maintenance': 'cryers-hill-property-maintenance',
    'holtspur-property-maintenance': 'holtspur-property-maintenance',
    'piddington-property-maintenance': 'piddington-property-maintenance',
    'medmenham-property-maintenance': 'medmenham-property-maintenance',
    'hambleden-property-maintenance': 'hambleden-property-maintenance',
    'little-marlow-property-maintenance': 'little-marlow-property-maintenance',
    'taplow-property-maintenance': 'taplow-property-maintenance',
    'denham-property-maintenance': 'denham-property-maintenance',
    'west-wycombe-property-maintenance': 'west-wycombe-property-maintenance',
};

module.exports = navRoutes;
